<template>
  <div>
    <!-- Show the header option -->
    <div class="d-flex justify-end mb-6">
      <!-- Show the add button -->
      <v-btn
        depressed
        class="mr-3"
        color="primary"
        :disabled="isMakingRequest"
        @click="addAnotherTask"
      >
        <v-icon left>
          add
        </v-icon>

        Add Task
      </v-btn>

      <!-- Show the add button -->
      <v-btn
        depressed
        color="primary"
        :loading="isMakingRequest"
        :disabled="isMakingRequest"
        @click="handleSubmitRequest"
      >
        <v-icon left>
          refresh
        </v-icon>

        Save
      </v-btn>
    </div>

    <v-subheader
      class="d-flex justify-space-between primary white--text"
    >
      <span>
        Tasks
      </span>

      <div class="d-flex">
        <span class="mr-8">
          <b class="text-h6 pr-1">{{ tasks.length }}</b>
          tasks
        </span>

        <span :title="cost">
          <b class="text-h6 pr-1">{{ nFormatter(cost) }}</b>
          cost
        </span>
      </div>
    </v-subheader>

    <!-- In case there are no tasks yet -->
    <div
      v-if="formData.length === 0"
      class="white mt-0 px-4 py-8 d-flex justify-center"
    >
      <!-- Show the animation here -->
      <div style="max-width: 360px">
        <lottie-animation
          loop
          file="25943-nodata.json"
        />
      </div>
    </div>

    <!-- Otherwise -->
    <template v-else>
      <v-hover
        v-for="(v, index) in $v.formData.$each.$iter"
        :key="index"
        v-slot="{ hover }"
      >
        <v-row
          class="white mt-0 px-4 pt-4"
        >
          <!-- Type value for the item -->
          <v-col
            cols="12"
            md="6"
            lg="3"
          >
            <v-select
              v-model="v.type.$model"
              :items="selectOptions.type"
              :hide-details="!v.type.$anyError"
              :error-messages="v.type.$anyError ? ['Please select a value'] : null"
              :disabled="isMakingRequest"
              @blur="v.type.$touch"
              label="Type"
              outlined
            />
          </v-col>

          <!-- Price value for the item -->
          <v-col
            cols="12"
            md="6"
            lg="3"
          >
            <v-text-field
              :value="getPriceValue(v.type.$model)"
              :disabled="isMakingRequest"
              placeholder="Select a type"
              label="Price"
              outlined
              readonly
            />
          </v-col>

          <!-- Date value for the item -->
          <v-col
            cols="12"
            md="6"
            lg="3"
          >
            <v-menu
              v-model="v.$model.shouldShowDatePicker"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :value="v.date.$model ? dayjs(v.date.$model).format('ll') : null"
                  :hide-details="!v.date.$anyError"
                  :error-messages="v.date.$anyError ? ['Please select a date'] : null"
                  :disabled="isMakingRequest"
                  @blur="v.date.$touch"
                  label="Date"
                  prepend-inner-icon="today"
                  outlined
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                color="primary"
                show-current
                show-adjacent-months
                v-model="v.date.$model"
                @input="v.$model.shouldShowDatePicker = false"
              ></v-date-picker>
            </v-menu>
          </v-col>

          <!-- Type value for the item -->
          <v-col
            cols="12"
            md="6"
            lg="3"
            class="d-flex"
          >
            <!-- Show select option for status -->
            <v-select
              v-model="v.status.$model"
              :items="selectOptions.status"
              :hide-details="!v.status.$anyError"
              :error-messages="v.status.$anyError ? ['Please select a value'] : null"
              :disabled="isMakingRequest"
              @blur="v.status.$touch"
              label="Status"
              outlined
            />

            <!-- Show a delete icon next to it -->
            <v-btn
              icon
              class="ml-2 mt-2"
              color="primary"
              :disabled="isMakingRequest"
              @click="handleDeleteClick(v.$model)"
            >
              <v-icon
                :class="{ 'opacity-0': !hover }"
              >
                clear
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-hover>
    </template>

    <!-- The dialog for delete confirmation -->
    <v-dialog
      v-model="shouldShowDeleteDialog"
      max-width="500"
      :persistent="isMakingRequest"
    >
      <v-card>
        <v-card-title>
          Delete this task?
        </v-card-title>

        <v-card-text>
          Are you sure you want to remove this task?
        </v-card-text>

        <v-card-actions>
          <v-spacer />

          <v-btn
            text
            color="primary"
            :disabled="isMakingRequest"
            @click="shouldShowDeleteDialog = false"
          >
            Cancel
          </v-btn>

          <v-btn
            depressed
            color="primary"
            :loading="isMakingRequest"
            :disabled="isMakingRequest"
            @click="handleDeleteRequest"
          >
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// Import helper functions
import { required, minLength, maxLength, url } from "vuelidate/lib/validators"

// Import children components
const LottieAnimation = () => import(/* webpackChunkName: "lottie-animation" */ "@/components/common/LottieAnimation.vue")

// Define generator function
const generateForm = () => ({
  symbol: Symbol(),
  id: null,
  type: null,
  date: null,
  url: "",
  status: "pending",
  shouldShowDatePicker: false
})

// Export the SFC
export default {
  // Name of the component
  name: "InfluencerOutreachTasks",

  // Register children components
  components: {
    LottieAnimation
  },

  // Accept incoming data from parent
  props: {
    influencer: {
      type: Object,
      required: true
    },

    data: {
      type: Object,
      required: true
    }
  },

  // Define local data variables
  data: () => ({
    // Whether or not if we're making a network request right now
    isMakingRequest: false,

    // Whether or not to show the delete dialog box
    shouldShowDeleteDialog: false,

    // The selected item to be deleted
    selectedItem: null,

    // The form inputs
    formData: [
      generateForm()
    ],

    // Define select option values
    selectOptions: {
      type: [
        {
          text: "Story",
          value: "story"
        },
        {
          text: "Photo",
          value: "photo"
        },
        {
          text: "Video",
          value: "video"
        },
        {
          text: "Carousel",
          value: "carousel"
        }
      ],
      status: [
        {
          text: "Pending",
          value: "pending",
        },
        {
          text: "Accepted",
          value: "accepted",
        },
        {
          text: "Rejected",
          value: "rejected",
        },
        {
          text: "Posted",
          value: "posted",
        },
        {
          text: "Delayed",
          value: "delayed",
        }
      ]
    }
  }),

  // Define readonly computable variables
  computed: {
    /**
     * Get all the tasks related to this influencer
     *
     * @returns {Array}
     */
    tasks() {
      return this.data.tasks.filter((search) => search.campaign_report_influencer_id === this.influencer.id)
    },

    /**
     * Get the total cost value for this influencer
     *
     * @returns {Number}
     */
    cost() {
      return !this.influencer.prices ? 0 : this.tasks.reduce((a, b) => a + Number(this.influencer.prices[b.type] || 0), 0)
    }
  },

  // Set vuex deep watchers
  watch: {
    /**
     * As soon as the tasks value is updated
     *
     * @returns {void}
     */
    "data.tasks"() {
      this.feedFormData()
    }
  },

  // Define vuelidate validations
  validations: {
    formData: {
      required,
      minLength: minLength(1),
      maxLength: maxLength(1000),
      $each: {
        type: {
          required,
        },
        status: {
          required,
        },
        date: {
          required,
        },
        url: {
          url
        }
      }
    }
  },

  // Define local method functions
  methods: {
    /**
     * Make a network request to save or update the form items
     *
     * @returns {void}
     */
    async handleSubmitRequest() {
      // Run validations on all inputs
      await this.$v.formData.$touch()

      // If there's any error
      if (this.$v.formData.$anyError) {
        // Stop further execution
        return
      }

      // Otherwise, show a loader
      const loaderId = Symbol()
      this.$store.dispatch("loaders/add", loaderId)
      this.isMakingRequest = true

      // Loop through each of the form items
      for (const item of this.formData) {
        // Try making a network request
        try {
          // Use helper function
          const response = await axios({
            url: `/api/influencer-outreach/${this.influencer.campaign_report_id}/influencers/${this.influencer.id}/tasks/${item.id ? item.id : ''}`,
            method: (item.id === null) ? "POST" : "PUT",
            data: {
              type: item.type,
              post_url: item.url,
              status: item.status,
              scheduled_at: item.date,
            }
          })

          // Based on the request type
          if (item.id) {
            // Dispatch the Vuex action
            this.$store.dispatch("influencerOutreach/updateTask", response.data)
          }
          // Otherwise
          else {
            // Dispatch the Vuex action
            this.$store.dispatch("influencerOutreach/createTask", response.data)
          }
        }
        // Catch any error
        catch (error) {
          // Log the error
          logger({ type: "InfluencerOutreachTask Create/Update Error", error })

          // Show a message
          this.$store.dispatch("toasts/add", { text: "An error occurred!" })
        }
      }

      // At last, hide the loader
      this.$store.dispatch("loaders/remove", loaderId)
      this.isMakingRequest = false
    },

    /**
     * Make a network request to delete the form item
     */
    async handleDeleteRequest() {
      // If there's no temporary selected item
      if (!this.selectedItem) {
        // Abort further execution
        return
      }

      // Otherwise, show a loader
      const loaderId = Symbol()
      this.$store.dispatch("loaders/add", loaderId)
      this.isMakingRequest = true

      // Try making a network request
      try {
        // Use helper function
        await axios({
          url: `/api/influencer-outreach/${this.influencer.campaign_report_id}/influencers/${this.influencer.id}/tasks/${this.selectedItem.id}`,
          method: "DELETE",
        })

        // If successfully deleted, find the model from store
        const item = this.data.tasks.find((search) => search.id === this.selectedItem.id)

        // If the task model has been found
        if (item) {
          // Remove it from the store
          this.$store.dispatch("influencerOutreach/deleteTask", item)
        }
      }
      // Catch an error
      catch (error) {
        // Log the error
        logger({ type: "InfluencerOutreachInfluencerTask Delete Error", error })

        // Show a message
        this.$store.dispatch("toasts/add", { text: "An error occurred!" })
      }
      // Nevertheless
      finally {
        // Hide the loader
        this.$store.dispatch("loaders/remove", loaderId)
        this.shouldShowDeleteDialog = false
        this.isMakingRequest = false
        this.selectedItem = null
      }
    },

    /**
     * Remove the task instance item from local store
     * Or if it's been saved in database, remove it using API as well
     *
     * @returns {void}
     */
    handleDeleteClick(item) {
      // If the item does not have an id
      if (!item.id) {
        // Remove this element from the items list
        const index = this.formData.findIndex((search) => search.symbol === item.symbol)

        // If the index exists
        if (index !== -1) {
          // Remove the element
          this.formData.splice(index, 1)
        }
      }
      // Otherwise
      else {
        // Store the item in a temporary value
        this.selectedItem = item

        // Show the delete confirmation dialog
        this.shouldShowDeleteDialog = true
      }
    },

    /**
     * Push another task instance into the local store
     *
     * @returns {void}
     */
    addAnotherTask() {
      this.formData.push(generateForm())
    },

    /**
     * Get the price value for this type of post of this influencer
     *
     * @param {String} type
     * @returns {Number|Null}
     */
    getPriceValue(type) {
      // If the type is not selected yet
      if (!type) {
        // Respond with empty value
        return null
      }

      // Otherwise
      return !this.influencer.prices || !this.influencer.prices[type] ? 'Price not set' : nFormatter(this.influencer.prices[type])
    },

    /**
     * Update the form data items based on the prop data
     *
     * @returns {void}
     */
    feedFormData() {
      // Reset the formData
      this.formData = []

      // For each task item
      for (const item of this.tasks) {
        // Append a form data row
        this.formData.push({
          symbol: Symbol(),
          id: item.id,
          type: item.type,
          status: item.status,
          url: item.post_url,
          date: dayjs(item.scheduled_at).format("YYYY-MM-DD"),
          shouldShowDatePicker: false
        })
      }
    }
  },

  /**
   * As soon as the component data is ready
   *
   * @returns {void}
   */
  created() {
    // Update the form data with input values
    this.feedFormData()
  }
}
</script>
